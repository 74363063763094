// Must include mobiscroll first for effective style overrides
import './assets/mobiscroll/mobiscroll.react.min.css';
import './assets/mobiscroll/mobiscroll.react.overrides.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css'; //core css
import '../src/assets/theme.css'; //theme
import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ApplicationHandler from './components/__Applications/_ApplicationHandler/ApplicationHandler';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'ADMIN') {
    return import('./components/__Applications/AdminApp');
  } else {
    return import('./components/__Applications/ClientApp');
  }
}

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  const dsnUrl = getSentryDsn();
  Sentry.init({
    dsn: dsnUrl as string,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.GIT_SHA,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

importBuildTarget().then(({ default: Environment }) => {
  return root.render(
    <React.StrictMode>
      <ApplicationHandler>
        <Environment></Environment>
      </ApplicationHandler>
    </React.StrictMode>,
  );
});

function getSentryDsn() {
  if (process.env.REACT_APP_BUILD_TARGET === 'ADMIN') {
    return process.env.REACT_APP_ADMIN_SENTRY_DSN;
  }
  if (process.env.REACT_APP_BUILD_TARGET === 'CLIENT') {
    return process.env.REACT_APP_CLIENT_SENTRY_DSN;
  }
  throw new Error(
    'Cannot initialized Sentry.io.  Unknown build target in process.env.REACT_APP_BUILD_TARGET',
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
