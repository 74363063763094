import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import A11yNavigationAnnouncer from '../../A11yNavigationAnnouncer';
import Auth0ProviderWithHistory from '../../Auth/auth0-provider-with-history';

const ApplicationHandler: React.FC<{children: JSX.Element}> = (props): JSX.Element => {
  return (
    <BrowserRouter>
      {/* React Router has no built-in screen reader announcements when navigation changes.
    Some form of announcing is required to let screen readers know they've navigated successfully. */}
      <A11yNavigationAnnouncer />
      {/* @ts-ignore */}
      <Auth0ProviderWithHistory>{props.children}</Auth0ProviderWithHistory>
    </BrowserRouter>
  );
};

export default ApplicationHandler;
