import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Credit: https://estevanmaito.me/blog/accessible-navigation-using-react-router
 */

const AccessibleNavigationAnnouncer: React.FC = (): JSX.Element => {
  // the message that will be announced
  const [message, setMessage] = useState('');
  // get location from router
  const location = useLocation();

  // only run this when location change (note the dependency [location])
  useEffect(() => {
    // ignore the /
    if (location.pathname.slice(1)) {
      // make sure navigation has occurred and screen reader is ready
      setTimeout(
        () => setMessage(`Navigated to ${location.pathname.slice(1)} page.`),
        500,
      );
    } else {
      // '/' reroutes to other routes
      setMessage('');
    }
  }, [location]);

  return (
    // .sr-only comes from Tailwind CSS and makes sure it will only be visible for SRs
    <span
      className="sr-only"
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      {message}
    </span>
  );
};

export default AccessibleNavigationAnnouncer;
