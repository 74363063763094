/**
 * Our authentication strategy assumes we maintain auth state with React Context.
 * Currently leveraging an Auth0Provider for Auth0 integration.
 * auth0.com/blog/complete-guide-to-react-user-authentication/#Connect-React-with-Auth0
 **/

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId: string =
    process.env.REACT_APP_BUILD_TARGET === 'ADMIN' &&
    process.env.REACT_APP_AUTH0_CLIENT_ID_ADMIN
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_ADMIN
      : process.env.REACT_APP_AUTH0_CLIENT_ID_CLIENT
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_CLIENT
      : '';
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
  const scope = process.env.REACT_APP_SCOPE || '';

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope={scope}
      options={{
        allowSignUp: false,
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
